<template>
    <div class="top-writers_slide">
        <div class="top-writers_slide__avatar">
            <avatar
                :avatar-url="writer.avatar_url"
                :avatar-fallback="writer.avatar_fallback"
                :name="writer.name"
                :user="'writer'"
            />
            <span
                class="top-writers_slide__avatar--status"
                :class="writer.is_online ? 'online' : 'offline'"
            />
        </div>
        <p class="top-writers_slide__name">
            {{ writer.name }}
            (ID: {{ writer.id }})
        </p>
        <div class="top-writers_slide__rating">
            <star-rating
                :star-size="20"
                :increment="0.1"
                :rating="writer.statistics.rank_all / 2"
                :active-color="themeColor"
                :read-only="true"
            />
            <p
                class="top-writers_slide__rating-count"
                @click="openModalReview(writer.id)"
            >
                ({{ writer.statistics.rank_all_count }}) {{ writer.statistics.rank_all_count > 1 ? 'reviews' : 'review' }}
            </p>
        </div>
        <div
            v-if="writer.application.major && writer.application.major.value"
            class="top-writers_slide__education"
        >
            <SchoolOutline />
            <span class="top-writers_slide__education-description">{{ writer.application.major.value }}</span>
        </div>
        <div class="top-writers_slide__actions">
            <router-link
                :to="{ name: 'writer_about', params: { id: writer.id } }"
                class="btn-base btn-main"
            >
                view profile
            </router-link>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/common/Avatar.vue'
import SchoolOutline from 'mdi-vue/SchoolOutline.vue';
import StarRating from 'vue-star-rating'

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    components: {
        Avatar,
        SchoolOutline,
        StarRating
    },
    props: {
        writer: {
            type: Object,
            required: true
        }
    },
    computed: {
        themeColor() {
            return EnvSettings.styleSetting.color
        }
    }
}
</script>
