<template>
    <div class="support-banner">
        <div class="support-banner__contant">
            <p class="support-banner__title">
                Can’t find the right person?
            </p>
            <p class="support-banner__message">
                Contact us and our team will help you to find more experts.
            </p>
            <button
                class="btn-base btn-main"
                @click="$router.push({ name: 'support_form' })"
            >
                Contact us
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SupportBanner'
}
</script>

<style lang="scss">
.support-banner {
    position: relative;
    padding-top: 202px;
    max-width: 375px;
    width: 100%;
    height: 388px;
    background: #263448;
    color: #fff;
    border-radius: 4px;
    overflow: hidden;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 182px;
        background: url("~@/assets/img/svg/support-banner-bg.svg") center / cover no-repeat;
        @media (max-width: 1200px) {
            background: url("~@/assets/img/svg/support-banner-bg-mobile.svg") center / cover no-repeat;
        }
    }
    &__contant {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    &__title {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 18px;
    }
    &__message {
        margin-bottom: 26px;
        font-size: 14px;
        line-height: 20px;
    }
}
</style>
