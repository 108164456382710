<template>
    <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.3333 2H15.5333C17.2134 2 18.0535 2 18.6952 2.32698C19.2597 2.6146 19.7187 3.07354 20.0063 3.63803C20.3333 4.27976 20.3333 5.11984 20.3333 6.8V15.2C20.3333 16.8802 20.3333 17.7202 20.0063 18.362C19.7187 18.9265 19.2597 19.3854 18.6952 19.673C18.0535 20 17.2134 20 15.5333 20H7.13325C5.45309 20 4.61302 20 3.97128 19.673C3.40679 19.3854 2.94785 18.9265 2.66023 18.362C2.33325 17.7202 2.33325 16.8802 2.33325 15.2V11M7.33325 12V16M15.3333 10V16M11.3333 6V16M1.33325 4L4.33325 1M4.33325 1L7.33325 4M4.33325 1L4.33325 7"
            stroke="#FF690C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Score'
}
</script>
<style scoped lang='scss'>
svg {
    margin-right: 10px;
}
svg path {
    stroke: $main-color;
}
</style>
