<template>
    <div class="express-order_steps-container">
        <ul class="express-order_steps">
            <div class="express-order_steps_line">
                <div class="express-order_steps_line_progress" />
            </div>
            <li class="express-order_steps_item">
                <span class="express-order_steps_circle express-order_steps_circle--complete">
                    <Check />
                </span>
                Submit a project
            </li>
            <li class="express-order_steps_item">
                <span class="express-order_steps_circle express-order_steps_circle--active">
                    2
                </span>
                Writer auction
            </li>
            <li class="express-order_steps_item">
                <span class="express-order_steps_circle">
                    3
                </span>
                In progress
            </li>
            <li class="express-order_steps_item">
                <span class="express-order_steps_circle">
                    4
                </span>
                Under warranty
            </li>
            <li class="express-order_steps_item">
                <span class="express-order_steps_circle">
                    5
                </span>
                Completed
            </li>
        </ul>
    </div>
</template>

<script>
import Check from 'mdi-vue/Check'

export default {
    name: 'OrderExpressSteps',
    components: {
        Check
    }
}
</script>
