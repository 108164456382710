<template>
    <div class="bid-snackbar">
        <transition-group name="bid_slide">
            <div
                v-for="(bid) in snackbar"
                :key="bid.sw_id"
                class="bid-snackbar_item"
            >
                <div class="bid-snackbar_item__avatar">
                    <Avatar
                        :avatar-url="bid.avatar_url"
                        :avatar-fallback="bid.avatar_fallback"
                        :nickname="bid.username"
                        user="writer"
                    />
                </div>
                <div class="bid-snackbar_item__details">
                    <p class="bid-snackbar_title">
                        {{ bid.username }} (id: {{ bid.sw_id }})
                    </p>
                    <p>looking at your project right now</p>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import Avatar from '@/components/common/Avatar.vue';

export default {
    components: {
        Avatar
    },
    props: {
        snackbar: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss">
    .bid-snackbar{
        position: fixed;
        bottom: 30px;
        left: 20px;
        z-index: 999;
        &_item{
            background: #fff;
            padding: 10px;
            display: flex;
            border-radius: 4px;
            margin: 10px 0;
            box-shadow: 0px 8px 48px -10px rgba(16, 24, 40, 0.18);
            &__avatar{
                margin-right: 10px;
            }
        }
        &_title{
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
    .bid_slide-enter-active {
        transition: all .3s ease;
    }
    .bid_slide-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .bid_slide-enter, .bid_slide-leave-to {
        transform: translateX(-20px);
        opacity: 0;
    }
</style>
