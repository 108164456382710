<template>
    <div
        v-if="!isLoading"
        class="top-writers"
    >
        <div class="top-writers_wrapper">
            <div class="top-writers_text">
                <div class="top-writers_icon">
                    <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                    >
                        <path
                            d="M20 9L8 9M20 3L8 3M20 15L8 15M4 9C4 9.55229 3.55228 10 3 10C2.44772 10 2 9.55229 2 9C2 8.44772 2.44772 8 3 8C3.55228 8 4 8.44772 4 9ZM4 3C4 3.55228 3.55228 4 3 4C2.44772 4 2 3.55228 2 3C2 2.44772 2.44772 2 3 2C3.55228 2 4 2.44772 4 3ZM4 15C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14C3.55228 14 4 14.4477 4 15Z"
                            stroke="#FF690C"
                            stroke-width="2"
                        />
                    </svg>
                </div>
                <div class="">
                    <p class="top-writers_title">
                        Other top experts you can request
                    </p>
                    <p>
                        Not all our experts are currently online. You can still order and request a specific writer.
                    </p>
                </div>
            </div>
            <div class="top-writers_nav">
                <div
                    class=""
                    @click="$refs.slider.prev()"
                >
                    <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                    >
                        <path
                            d="M7 13L1 7L7 1"
                            stroke="#111720"
                            stroke-width="2"
                        />
                    </svg>
                </div>
                <div
                    class=""
                    @click="$refs.slider.next()"
                >
                    <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                    >
                        <path
                            d="M1 13L7 7L1 1"
                            stroke="#111720"
                            stroke-width="2"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <div class="slider-wrapper">
            <VueSlickCarousel
                v-if="!isLoading"
                ref="slider"
                class="top-writers_slider"
                :arrows="false"
                :slides-to-show="4"
                :responsive="responsive"
            >
                <div
                    v-for="writer in writersList"
                    :key="writer.id"
                    class="top-writers_slide_wrapper drop-shadow"
                >
                    <TopWriterSliderSlide
                        :writer="writer"
                    />
                </div>
            </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import TopWriterSliderSlide from '@/components/topWritersSlider/TopWriterSliderSlide'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: {
        VueSlickCarousel,
        TopWriterSliderSlide
    },
    data() {
        return {
            isLoading: false,
            writersList: null
        }
    },
    computed: {
        responsive() {
            return [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        }
    },
    async created() {
        await this.getWriters()
    },
    methods: {
        async getWriters() {
            try {
                this.isLoading = true
                const { data } = await Api.get('/api/writer/list-by-params', { mode: 'list' })
                this.writersList = data.data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>

<style lang="scss">
    .top-writers {
        overflow: hidden;
        &_wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        &_nav{
            display: flex;
            & > div {
                cursor: pointer;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &_text{
            display: flex;
            color: #111720;
        }
        &_icon{
            width: 48px;
            height: 48px;
            background: #FFEEE6;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
        }
        &_title{
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
        }
        &_slide_wrapper {
            padding-right: 20px;
        }
        &_slide{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #111720;
            border-radius: 4px;
            padding: 20px;
            background: #fff;
            position: relative;
            height: 380px;
            &__avatar {
                margin-bottom: 18px;
                position: relative;
                .avatar-img  {
                    width: 120px;
                    height: 120px;
                }
                &--status{
                    position: absolute;
                    top: 15px;
                    right: 5px;
                    border: 2px solid #FFFFFF;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    &.online {
                        background: #27D91F;
                    }
                    &.offline {
                        background: #B71A1A;
                    }
                }
            }
            &__name{
                font-size: 18px;
                line-height: 26px;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                text-align: center;
            }
            &__rating {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                &-count {
                    margin-left: 5px;
                }
            }
            &__education{
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #111720;
                display: flex;
                margin-bottom: 20px;
                width: 100%;
                svg {
                    margin-right: 10px;
                }
            }
            &__education-description{
                height: 57px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
            }
            &__actions {
                margin-top: auto;
            }
        }
        .slider-wrapper {
            width: calc(100% + 20px);
            overflow: hidden;
            margin-bottom: 20px;
        }
        .slick-list {
            overflow: visible;
        }
        .slick-slide .drop-shadow {
            box-shadow: none;
            transition: box-shadow 0.1s ease-in-out;
        }

        .slick-slide.slick-active .drop-shadow {
            box-shadow: 0px 8px 48px -10px rgba(16, 24, 40, 0.18);
        }

    }
</style>
