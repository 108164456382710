<template>
    <div
        class="bids_item"
        :class="{
            'skeleton': skeleton,
            'bids_item--updated': data.isUpdated,
            'bids_item--canceled': data.canceled
        }"
    >
        <div class="bids_item__avatar without_skeleton">
            <avatar
                :avatar-url="data.writer.avatar_url"
                :avatar-fallback="data.writer.avatar_fallback"
                :nickname="data.writer.name"
                :user="'writer'"
            />
            <span
                class="bids_item__avatar--status"
                :class="statusWriterOnline.class"
            />
        </div>
        <div class="bids_item__info without_skeleton">
            <div class="bids_item__info-header">
                <p class="bids_item__info-writer-name">
                    {{ data.writer.name }}
                </p>
                <span class="bids_item__info-writer-id">
                    ID: {{ data.writer.id }}
                </span>
                <div
                    class="bids_item__info-writer-status"
                    :class="statusWriterOnline.class"
                >
                    {{ statusWriterOnline.label }}
                </div>
            </div>
            <div class="bids_item__rating">
                <star-rating
                    :star-size="14"
                    :increment="0.1"
                    :rating="data.writer.statistics.rank_all / 2"
                    :active-color="themecolor"
                    :read-only="true"
                />
                <p
                    class="bids_item__rating-count"
                    @click="openModalReview(data.writer.id)"
                >
                    ({{ data.writer.statistics.rank_all_count }}) {{ data.writer.statistics.rank_all_count > 1 ? 'reviews' : 'review' }}
                </p>
            </div>
            <div class="bids_item__edu">
                <SchoolOutline />
                Bachelor, Journalism and Mass Communication
            </div>
            <div class="bids_item__data">
                {{ data.writer.personal_statement }}
            </div>
        </div>
        <div class="bids_item__actions without_skeleton">
            <div
                class="bids_item__payment"
                :class="{'bids_item__payment--canceled': data.canceled}"
            >
                <p> Writer's bid: <span class="bids_item__payment--big">${{ data.total }}</span></p>
                <div
                    v-if="data.canceled"
                    class="bids_item__canceled"
                >
                    Bid was canceled
                </div>
            </div>

            <div
                v-if="!data.canceled"
                class="bids_item__buttons without_skeleton"
            >
                <button
                    class="btn-base btn-main"
                    @click="hireWriter"
                >
                    Hire expert
                </button>
                <button
                    class="btn-base btn-border"
                    @click="startChat(data)"
                >
                    <chat-outline />
                    Start chat
                </button>
                <button
                    class="bids_item__buttons-more"
                    @click="showMore"
                >
                    <span>
                        <img
                            :src="require(`@/assets/img/svg/arrow.svg`)"
                            class="bids_item__buttons-more-arrow"
                            :class="{ 'active': showStatistics }"
                            alt="arrow"
                        >
                        Show more
                    </span>
                </button>
            </div>
        </div>
        <div
            class="bids_item__writer-statistics without_skeleton"
            :class="{ 'active': showStatistics }"
        >
            <div class="bids_item__writer-statistics-item">
                <p class="bids_item__writer-statistics-title">
                    Completed orders
                </p>
                <p class="bids_item__writer-statistics-data">
                    <file />
                    {{ data.writer.statistics.orders_total }}
                </p>
            </div>
            <div class="bids_item__writer-statistics-item">
                <p class="bids_item__writer-statistics-title">
                    Reviews
                </p>
                <p class="bids_item__writer-statistics-data">
                    <score />
                    {{ data.writer.statistics.rank_all_count }}
                </p>
            </div>
            <div class="bids_item__writer-statistics-item">
                <p class="bids_item__writer-statistics-title">
                    Review score
                </p>
                <p class="bids_item__writer-statistics-data">
                    <file />
                    {{ data.writer.statistics.rank_all }}
                </p>
            </div>
        </div>
        <div class="bids_item__footer">
            <div
                v-if="getLabelText"
                class="bids_item__label"
                :class="getLabelClass"
            >
                {{ getLabelText }}
            </div>
            <div class="bids_item__footer-dates">
                <div>
                    <div class="bids_item__footer-dates-title">
                        Latest offer:
                    </div>
                    <div>
                        {{ data.created_at | moment_from }} ago
                    </div>
                </div>
                <div>
                    <div class="bids_item__footer-dates-title">
                        Delivery date:
                    </div>
                    <div>
                        {{ data.due_at | moment_bidding_due_at }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import filtersMixin from '@/mixins/filtersMixin.js'
import StarRating from 'vue-star-rating'
import SchoolOutline from 'mdi-vue/SchoolOutline.vue';
import Avatar from '@/components/common/Avatar.vue'
import File from '@/components/icons/File.vue';
import Score from '@/components/icons/Score.vue';
import ChatOutline from '@/components/icons/ChatOutline.vue';
import moment from 'moment';
import {
    createChat
} from '@/services/writersChat'
// import { mapSupportFields } from '@/store/modules/support';

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    components: {
        StarRating,
        SchoolOutline,
        ChatOutline,
        File,
        Avatar,
        Score
    },
    mixins: [filtersMixin],
    props: {
        data: {
            type: Object,
            required: true
        },
        skeleton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showStatistics: false
        }
    },
    computed: {
        getAvatar() {
            if (this.data.writer.avatar_url) {
                return this.data.writer.avatar_url
            } if (this.data.writer.avatar_fallback) {
                return `/img/avatars/${this.data.writer.avatar_fallback}`
            }
            return null
        },
        getInitials() {
            return this.data.slice(0, 1).toUpperCase()
        },
        themecolor() {
            return EnvSettings.styleSetting.color
        },
        getTimeCreatedFromNow() {
            const created_at = moment().diff(moment(this.data.created_at), 'minutes');
            return created_at
        },
        getTimeUpdatedFromNow() {
            const created_at = moment().diff(moment(this.data.updated_at), 'minutes');
            return created_at
        },
        getLabelClass() {
            const showCanceledLabel = this.data.canceled
            const showUpdatedLabel = this.data.isUpdated
            const showCreatedLabel = this.data.isNew

            return {
                'bids_item__label--success': showCreatedLabel,
                'bids_item__label--warning': showUpdatedLabel,
                'bids_item__label--error': showCanceledLabel
            }
        },
        getLabelText() {
            const showCanceledLabel = this.data.canceled
            const showUpdatedLabel = this.data.isUpdated
            const showCreatedLabel = this.data.isNew

            if (showCreatedLabel) {
                return 'Just received'
            }
            if (showUpdatedLabel) {
                return 'Updated'
            }
            if (showCanceledLabel) {
                return 'Canceled'
            }
            return ''
        },
        lastSeenDifference() {
            const start = moment(this.orderCreatedDate)
            const now = moment()
            const diff = moment.duration(now.diff(start)).asSeconds().toFixed(0)
            // eslint-disable-next-line no-mixed-operators
            return Math.floor(diff % (3600 * 24) / 3600);
        },
        statusWriterOnline() {
            const fromNow = moment(moment(this.data.writer.last_seen)).fromNow()
            if (this.data.writer.is_online) {
                return {
                    class: 'active',
                    label: 'Online'
                }
            }
            if (this.lastSeenDifference < 3) {
                return {
                    class: 'offline-shorttime',
                    label: `Last seen ${fromNow}`
                }
            }

            return {
                class: 'offline',
                label: `Last seen ${fromNow}`
            }
        }
    },
    methods: {
        hireWriter() {
            this.$emit('hireWriter', this.data.id)
        },
        showMore() {
            this.showStatistics = !this.showStatistics
        },
        openModalReview(writerId) {
            this.$emit('openModalReview', writerId)
        },
        async startChat(bid) {
            const chatId = await createChat({
                orderid: this.$route.params.id,
                sw_id: bid.writer.id
            })
            eventBus.$emit('openMessenger', chatId, 'writer-chat');
        }
    }
}
</script>
