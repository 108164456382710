<template>
    <div
        v-if="dialog"
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Assignment to writer</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                >
                    <CloseIcon />
                </span>
            </header>
            <section class="confirm-modal__content">
                <ul>
                    <li>
                        The writer won't get paid until the client accepts the job
                    </li>
                    <li>
                        You can get acquainted with our
                        <router-link
                            class="link"
                            :to="{ name: 'terms' }"
                        >
                            term of services
                        </router-link>,
                        <router-link
                            class="link"
                            :to="{ name: 'privacy' }"
                        >
                            privacy
                        </router-link>
                    </li>
                </ul>
            </section>
            <footer>
                <div class="confirm-modal__two-button-block">
                    <!-- <custom-button
                        :loading="assigneLoading"
                        default
                        class="confirm-modal__two-button confirm-modal__two-button--gray"
                        @on-btn-click="close"
                    >
                        cancel
                    </custom-button> -->
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--gray"
                        @click.prevent="close()"
                    >
                        cancel
                    </button>
                    <custom-button
                        :loading="assigneLoading"
                        height="60px"
                        class="confirm-modal__two-button confirm-modal__two-button--main"
                        @on-btn-click="submit"
                    >
                        proceed
                    </custom-button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import CloseIcon from 'mdi-vue/Close.vue';

export default {
    name: 'AssigneWriterModal',
    components: {
        CloseIcon
    },
    props: {
        assigneLoading: {
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null
    }),
    methods: {
        open() {
            this.dialog = true
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        submit() {
            this.resolve(true)
        },
        close() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>

<style lang="scss" scoped>
.link {
    color: $main-color;
    text-decoration: underline;
}
</style>
